import React from 'react';
import styled from 'styled-components';
import { WarningIcon } from '../Icons/Icons';

const StyledErrorPanel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #333;

  .icon {
    font-size: 5rem;
    margin-bottom: 1.5rem;
  }
`;

const ErrorPanel = (props: any) => {
  const message = props.message || 'There was an error.';
  return (
    <StyledErrorPanel>
      <WarningIcon />
      {message}
    </StyledErrorPanel>
  );
};

export default ErrorPanel;
