import React, { FormEvent } from 'react';
import { forgotPassword } from './resetPasswordUtils';
import { Link } from 'react-router-dom';
import { TextField, FormTitle, FormDescription, Button, FormActionsWrapper } from '@parkingsense/ui';
import { constants } from '@parkingsense/ui';
import { ResetPasswordSubFormProps } from './ResetPasswordForm';
import { FormValidationResult, FieldError } from '@parkingsense/ui';

export const validateGetCodeForm = (data: any): FormValidationResult => {
  const errors: FieldError = {};

  if (!Boolean(data.username)) {
    errors['username'] = 'Please enter a valid username';
  }

  return Object.keys(errors).length === 0 ? {} : { errors };
};

const GetCodeForm = (props: ResetPasswordSubFormProps) => {
  const [username, setUsername] = React.useState('');
  const [error, setError] = React.useState('');
  const handleFormSubmission = (e: FormEvent): void => {
    e.preventDefault();
    setError('');
    const validateResult = validateGetCodeForm({ username });
    if (validateResult.errors) {
      setError(validateResult.errors.username);
      return;
    }

    forgotPassword(username, {
      onSuccess: (data: any) => {
        props.onSetState('reset-password', { username });
        props.onMessage({
          type: 'info',
          text: `A verification code has been sent to your ${data.CodeDeliveryDetails.AttributeName}`
        });
      },
      onFailure: (err: any) => props.onMessage({ text: err.message, type: 'warning' })
    });
  };

  return (
    <form onSubmit={handleFormSubmission} data-test-id="getVerificationCodeForm">
      <FormTitle>Reset Password</FormTitle>
      <FormDescription>
        <p>Please enter your username.</p>
        <p>We will email you a verification code to reset your password.</p>
      </FormDescription>

      <TextField
        type="text"
        label="Username *"
        name="username"
        value={username}
        onChange={(e: any) => setUsername(e.target.value)}
        error={Boolean(error)}
        helperText={error}
      />
      <FormActionsWrapper>
        <Link data-test-id="goBackToLoginLink" to={constants.URLS.SIGN_IN}>
          Go back to login
        </Link>
        <br />
        <Button type="submit" data-test-id="sendMyCodeButton">
          Send my code
        </Button>
        <br />
        <Button data-test-id="toResetPasswordButton" onClick={() => props.onSetState('reset-password', { username })}>
          I have a code
        </Button>
      </FormActionsWrapper>
    </form>
  );
};

export default GetCodeForm;
