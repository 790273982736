import React from 'react';
import { Area, BayTypeSummary } from '../../types/LocationTypes';

export interface MapLocationInfoProps {
  name: string;
  address: string;
  areas: Array<Area>;
}

export const compareBayTypes = (a: string, b: string): number => {
  const bayTypeToValueMap = {
    S: 1,
    D: 2,
    R: 3,
    O: 4,
    O2: 5,
    O3: 6,
    O4: 7,
    O5: 8
  };

  const aValue = bayTypeToValueMap[a] || Infinity;
  const bValue = bayTypeToValueMap[b] || Infinity;

  return aValue > bValue ? 1 : -1;
};

/**
 *
 * @param bayTypeSummaries
 * @param
 * @param BayTypeSummary
 */
const getByTypeToLabelMap = (bayTypeSummaries: BayTypeSummary[]) => {
  const map: any = bayTypeSummaries.reduce((acc, curr) => {
    const currBayType = curr.bayType;
    if (acc[currBayType]) return acc;
    acc[currBayType] = curr.bayLabel;
    return acc;
  }, {});

  return map;
};

const MapLocationInfo = (props: MapLocationInfoProps) => {
  const combinedBayTypeSumamries = props.areas.reduce((acc: BayTypeSummary[], curr: Area) => {
    return acc.concat(curr.bayTypeSummaries);
  }, []);
  const bayTypeToLabelMap = getByTypeToLabelMap(combinedBayTypeSumamries);
  const availableBayTypes = Object.keys(bayTypeToLabelMap).sort(compareBayTypes);

  return (
    <div data-test-id="locationInfo">
      <h2 data-test-id="locationInfoTitle">{props.name}</h2>
      <p data-test-id="locationInfoAddress">{props.address}</p>
      <table>
        <thead data-test-id="locationInfoTableHeader" className="map-info__table-header">
          <tr>
            {availableBayTypes.map((type: string) => {
              return <td key={type}>{bayTypeToLabelMap[type]}</td>;
            })}
          </tr>
        </thead>
        <tbody data-test-id="locationInfoAreas">
          {props.areas.map((area: Area) => {
            const bayTypeToNumberMap: any = area.bayTypeSummaries.reduce((acc: any, curr: BayTypeSummary) => {
              acc[curr.bayType] = Number(acc[curr.bayType] || 0) + Number(curr.free);
              return acc;
            }, {});

            return (
              <tr key={area.uuid} data-test-id="locationInfoArea">
                {availableBayTypes.map((type, index) => {
                  return <td key={`${type}-${index}`}>{bayTypeToNumberMap[type] || 0}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default MapLocationInfo;
