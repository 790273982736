import React from 'react';
import styled from 'styled-components';
import { LoadingIcon } from '../Icons/Icons';

const StyledLoadingIndicator = styled.div`
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #333;

  .icon {
    font-size: 5rem;
    margin-bottom: 1.5rem;
    animation: spin 3s infinite;
  }
`;

const LoadingIndicator = (props: any) => {
  const message = props.message || 'Loading...';
  return (
    <StyledLoadingIndicator>
      <LoadingIcon />
      {message}
    </StyledLoadingIndicator>
  );
};

export default LoadingIndicator;
