import React from 'react';
import ReactDOMServer from 'react-dom/server';
import styled from 'styled-components';
import Map from 'google-map-react';
import { useQuery } from '@apollo/react-hooks';
import { GET_LOCATIONS } from '../../graphql/queries';
import ErrorPanel from '../ErrorPanel/ErrorPanel';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import MapLocationInfo from '../MapLocationInfo/MapLocationInfo';
import { publicGraphQlClient } from '../../helpers/apiClients';
import MapMarker from '../MapMarker/MapMarker';
import { Location, Area, BayTypeSummary } from '../../types/LocationTypes';

export const StyledMapDashboard = styled.nav`
  padding: 1rem;
  border: 1 solid;
  font-size: 1rem;
  font-weight: regular;
  text-align: left;
`;

export const MapDashboard = () => {
  const { loading, error, data } = useQuery(GET_LOCATIONS, {
    client: publicGraphQlClient,
    context: {
      headers: {
        apikey: process.env.REACT_APP_GRAPHQL_PUBLIC_APIKEY
      }
    }
  });
  const center = {
    lat: 40.6765,
    lng: -95.8587
  };
  let infoWindow: any = null;
  let gMaps: any = null;
  let gMap: any = null;
  let mapClickListner: any = null;

  React.useEffect(() => {
    return () => {
      if (gMaps && mapClickListner) {
        gMaps.event.removeListener(mapClickListner);
      }
    };
  });

  if (error) return <ErrorPanel />;
  if (loading) return <LoadingIndicator />;

  const locations: Location[] = data.location.results.map((loc: any) => {
    const { uuid, name, description, latitude, longitude, streetAddress } = loc;
    const areas: Area[] = loc.areas.results.map((area: any) => {
      const { uuid, name } = area;
      const bayTypeSummaries: BayTypeSummary[] = area.bayTypeSummary;
      return {
        uuid,
        name,
        bayTypeSummaries
      };
    });
    return {
      uuid,
      name,
      description,
      latitude,
      longitude,
      streetAddress,
      areas,
      bayTypeSummaries: loc.bayTypeSummary
    };
  });

  const handleMarkerClick = (uuid: string) => {
    const loc = locations.find(location => location.uuid === uuid);
    const content = (
      <div id="map-info-window">
        <MapLocationInfo name={loc!.name} address={loc!.streetAddress} areas={loc!.areas} />
      </div>
    );

    infoWindow.setOptions({
      content: ReactDOMServer.renderToStaticMarkup(content),
      pixelOffset: new gMaps.Size(0, -55),
      position: {
        lat: Number(loc!.latitude),
        lng: Number(loc!.longitude)
      }
    });
    infoWindow.open(gMap);
  };

  return (
    <StyledMapDashboard data-test-id="mapDashboard">
      <Map
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_APIKEY! }}
        center={center}
        defaultZoom={4}
        options={() => {
          return {
            disableDefaultUI: true
          };
        }}
        onGoogleApiLoaded={({ map, maps }) => {
          infoWindow = new maps.InfoWindow();
          gMaps = maps;
          gMap = map;

          mapClickListner = map.addListener('click', () => {
            infoWindow.close();
          });
        }}
        style={{ height: '100%', width: '100%' }}
        yesIWantToUseGoogleMapApiInternals={true}
      >
        {locations &&
          locations.map((loc: any) => {
            let freeSpaces = 0;
            let totalSpaces = 0;
            if (loc.bayTypeSummaries) {
              freeSpaces = loc.bayTypeSummaries.reduce((accum: number, curr: any) => {
                return accum + curr.free;
              }, 0);
              totalSpaces = loc.bayTypeSummaries.reduce((accum: number, curr: any) => {
                return accum + curr.total;
              }, 0);
            }

            return (
              <MapMarker
                key={loc.uuid}
                id={loc.uuid}
                lat={loc.latitude}
                lng={loc.longitude}
                totalSpaces={totalSpaces}
                freeSpaces={freeSpaces}
                zIndex={freeSpaces}
                onMarkerClick={handleMarkerClick}
              />
            );
          })}
      </Map>
    </StyledMapDashboard>
  );
};
