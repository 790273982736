import 'react-app-polyfill/ie11';
import 'core-js/stable';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import App from './App';
import { unregister as unregisterServiceWorker } from './serviceWorker';

import './index.css';

ReactDOM.render(<App />, document.getElementById('root') as HTMLElement);
unregisterServiceWorker();
