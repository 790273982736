import React from 'react';
import styled from 'styled-components';
import { breakpoints } from '@parkingsense/ui';
import NodeMasterListItem, { NodeMasterInterface } from '../NodeMasterListItem/NodeMasterListItem';
import { compareNodeMasters } from '../../helpers/nodeMaster';

const StyleNodeMasterList = styled.div`
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0;

    li {
      box-sizing: border-box;
      margin-bottom: 1.2rem;
      width: 100%;
    }

    @media ${breakpoints.md} {
      li {
        width: calc(50% - 0.7rem);
      }
    }

    @media ${breakpoints.lg} {
      li {
        width: calc(33% - 0.7rem);
      }
    }

    @media ${breakpoints.xl} {
      li {
        width: calc(20% - 0.7rem);
      }
    }
  }
`;

interface NodeMasterListProps {
  items: Array<NodeMasterInterface>;
}
const NodeMasterList: React.FC<NodeMasterListProps> = props => {
  const { items } = props;
  return (
    <StyleNodeMasterList>
      <ul data-test-id="nodeMastersList" className="nodemasters-list">
        {items.sort(compareNodeMasters).map(nodeMaster => (
          <NodeMasterListItem data-test-id="foo" key={nodeMaster.uuid} {...nodeMaster}>
            Node master
          </NodeMasterListItem>
        ))}
      </ul>
    </StyleNodeMasterList>
  );
};

export default NodeMasterList;
