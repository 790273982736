import moment from 'moment';
import { NodeMasterInterface, NodeMasterDisplayStatus } from '../components/NodeMasterListItem/NodeMasterListItem';

export const areKeywordsMatched = (keywords: string, nodeMaster: NodeMasterInterface): boolean => {
  const { name, organisationName, uuid, status } = nodeMaster;

  const toNotNull = (value?: string) => {
    if (!value) {
      return '';
    }

    return value;
  };

  const concatedProps = `
    ${toNotNull(name)} 
    ${toNotNull(organisationName)} 
    ${toNotNull(uuid)} 
    ${toNotNull(status && status.softwareVersion)}
  `;
  return concatedProps.toLowerCase().indexOf(keywords.trim().toLowerCase()) > -1;
};

export const getDislayStatusFor = (nodeMaster: NodeMasterInterface): NodeMasterDisplayStatus => {
  let title = '';
  let description = '';
  let momentLastContactTime;
  try {
    const momentLastContactTimeUTC = moment.utc(nodeMaster.lastContactTime);
    if (momentLastContactTimeUTC.isValid()) {
      momentLastContactTime = momentLastContactTimeUTC.local();
    } else {
      momentLastContactTime = null;
    }
  } catch {
    momentLastContactTime = null;
  }

  const secondsDelta = momentLastContactTime ? moment().diff(momentLastContactTime, 'seconds') : 999999999;

  if (secondsDelta > 3600) {
    title = 'warning';
    description = 'This nodemaster has not sent a notification in over an hour.';
    if (nodeMaster.status && nodeMaster.status.connected) {
      description += ' But it is online.';
    }
  } else if (nodeMaster && nodeMaster.status && nodeMaster.status.connected === true) {
    title = 'online';
    description = 'This nodemaster is online and responding correctly';
  } else {
    title = 'offline';
    description = 'This nodemaster is unresponsive';
  }

  return {
    title,
    description
  };
};

export const getValueFromDisplayStatus = (displayStatus: string): number => {
  switch (displayStatus) {
    case 'warning':
      return -1;
    case 'offline':
      return 0;
    case 'timeout':
      return 1;
    case 'online':
      return 2;
    default:
      return 3;
  }
};

export const compareNodeMasters = (a: NodeMasterInterface, b: NodeMasterInterface): number => {
  const aStatusValue = getValueFromDisplayStatus(getDislayStatusFor(a).title);
  const bStatusValue = getValueFromDisplayStatus(getDislayStatusFor(b).title);
  return aStatusValue > bStatusValue ? 1 : -1;
};
