import { CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';

const POOL_DATA = {
  UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID!,
  ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID!
};

export const userPool = new CognitoUserPool(POOL_DATA);

export const getCognitoUser = (username: string) => {
  const userData = {
    Username: username,
    Pool: userPool
  };
  return new CognitoUser(userData);
};

export const forgotPassword = (username: string, callbacks: any) => {
  const cognitoUser = getCognitoUser(username);

  cognitoUser.forgotPassword({
    onSuccess: (data: any) => {
      if (callbacks && typeof callbacks.onSuccess) {
        callbacks.onSuccess(data);
      }
    },
    onFailure: (err: any) => {
      if (callbacks && typeof callbacks.onFailure) {
        callbacks.onFailure(err);
      }
    }
  });
};

export const confirmPassword = (username: string, confirmationCode: string, newPassword: string, callbacks: any) => {
  const cognitoUser = getCognitoUser(username);
  cognitoUser.confirmPassword(confirmationCode, newPassword, {
    onSuccess: () => {
      if (callbacks && typeof callbacks.onSuccess) {
        callbacks.onSuccess();
      }
    },
    onFailure: (err: any) => {
      if (callbacks && typeof callbacks.onFailure) {
        callbacks.onFailure(err);
      }
    }
  });
};
