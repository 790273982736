import React from 'react';
import styled from 'styled-components';
import { constants } from '@parkingsense/ui';

export const markerDimensions = Object.freeze({
  width: 60,
  height: 60
});

export const StyledMapMarker = styled.div`
  .map-marker__wrapper {
    position: relative;
    width: ${markerDimensions.width}px;
    height: ${markerDimensions.height}px;
    left: -${markerDimensions.width / 2}px;
    top: -${markerDimensions.height}px;
  }

  .map-marker__text {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 80%; /* So text is vertically position at the widest space of the marker */
    justify-content: center;
    align-items: center;
    font-size: 14px;
    text-align: center;

    &--small {
      font-size: 11px;
    }
  }
`;

export const Marker = (props: { color: string }) => (
  <svg width="100%" height="100%" viewBox="0 0 62 62">
    <g transform="matrix(1,0,0,1,0.2,0)">
      <path
        d="M45.9,22.2C45.9,30.8 39.2,52.2 30.8,52.2C22.4,52.2 15.7,30.8 15.7,22.2C15.7,13.6 22.5,6.6 30.8,6.6C39.1,6.6 45.9,13.6 45.9,22.2Z"
        style={{ fill: 'white' }}
      />
    </g>
    <g id="nav:4_1_" transform="matrix(1,0,0,1,0.3,0)">
      <path
        d="M30.7,3.6C19.9,3.6 11.2,12.4 11.2,23.1C11.2,33.8 30.7,58.3 30.7,58.3C30.7,58.3 50.2,33.9 50.2,23.1C50.2,12.3 41.5,3.6 30.7,3.6ZM30.8,50.2C30.8,50.2 16.2,30.3 16.2,22.5C16.2,14.6 22.6,8.2 30.5,8.2C38.4,8.2 44.8,14.6 44.8,22.5C44.8,30.3 30.8,50.2 30.8,50.2Z"
        style={{ fill: props.color }}
      />
    </g>
  </svg>
);
export interface MapMarkerProps {
  id: string;
  lat: number;
  lng: number;
  totalSpaces: number;
  freeSpaces: number;
  zIndex: number;
  onMarkerClick: (uuid: string) => void;
}

export type FreeSpaceStatus = 'low' | 'moderate' | 'high';

const MapMarker = (props: MapMarkerProps) => {
  const freeSpacesPercent = props.totalSpaces > 0 ? Math.round((props.freeSpaces * 100) / props.totalSpaces) : 0;
  let freeSpaceStatus: FreeSpaceStatus = 'low';
  let markerColor = constants.COLORS.FREESPACE_LOW;

  if (freeSpacesPercent > 20) {
    freeSpaceStatus = 'high';
    markerColor = constants.COLORS.FREESPACE_HIGH;
  } else if (freeSpacesPercent > 1) {
    freeSpaceStatus = 'moderate';
    markerColor = constants.COLORS.FREESPACE_MODERATE;
  }

  return (
    <StyledMapMarker
      id={`map-marker-${props.id}`}
      style={{
        position: 'relative',
        zIndex: props.zIndex
      }}
    >
      <div className="map-marker__wrapper" onClick={() => props.onMarkerClick(props.id)}>
        <span className={`map-marker__image map-marker__image--${freeSpaceStatus}`}>
          <Marker color={markerColor} />
        </span>
        <span className={`map-marker__text ${props.freeSpaces > 999 ? 'map-marker__text--small' : ''}`}>
          {props.freeSpaces}
        </span>
      </div>
    </StyledMapMarker>
  );
};

export default MapMarker;
