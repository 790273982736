/** Default user auth object */
export const DEFAULT_USER_AUTH = Object.freeze({
  userId: 0,
  idToken: '',
  authenticated: false
});

export const DEFAULT_USER_DATA = Object.freeze({
  username: '',
  email: ''
});
