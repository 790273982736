import { createHttpLink } from 'apollo-link-http';
import ApolloClient from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { getStoredUserAuth } from '../components/views/AuthUtils/auth';

export const publicHttpLink = createHttpLink({
  uri: '/api/public/graphql'
});

export const publicGraphQlClient = new ApolloClient({
  link: publicHttpLink,
  cache: new InMemoryCache()
});

export const privateHttpLink = createHttpLink({
  uri: '/api/graphql'
});

export const getAuthLink = (global: any): any => {
  return setContext((_, { ...context }) => {
    const actualAuth = getStoredUserAuth(global);
    const token = actualAuth.idToken;
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        authorization: token
      },
      ...context
    };
  });
};

export const createPrivateGraphQlClient: any = (global: any) => {
  return new ApolloClient({
    link: getAuthLink(global).concat(privateHttpLink),
    cache: new InMemoryCache()
  });
};
