import * as React from 'react';
import { Redirect } from 'react-router';
/** Context consumer */
import { authContext } from '../../../contexts/AuthContext';
/** Login UI */
import SignInFormView from './SignInFormView';

const SignIn: React.FC<{}> = () => {
  const { auth } = React.useContext(authContext);
  if (auth.authenticated) {
    return <Redirect to="/" />;
  }
  return <SignInFormView />;
};
export default SignIn;
