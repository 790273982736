import * as React from 'react';
import { AuthenticationDetails, CognitoUserPool, CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import * as CustomTypes from '../../../types/AuthTypes';
import { USER_AUTH_KEY, USER_DATA_KEY } from './local-storage';
import { DEFAULT_USER_AUTH } from './constants';

const POOL_DATA = {
  UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID!,
  ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID!
};

const userPool = new CognitoUserPool(POOL_DATA);

/** Sign in and authenticate a user */
export const signIn = (
  username: string,
  password: string,
  history: History | any,
  setError: (error: string | null) => void,
  setTimestamp: React.Dispatch<React.SetStateAction<number>>,
  setAuthStatus: (userAuth: CustomTypes.UserAuth) => void,
  setUserData: (userData: CustomTypes.UserData) => void
) => {
  const authData = {
    Username: username,
    Password: password
  };

  const authDetails = new AuthenticationDetails(authData);

  const userData = {
    Username: username,
    Pool: userPool
  };

  const cognitoUser = new CognitoUser(userData);

  cognitoUser.authenticateUser(authDetails, {
    onSuccess(result: CognitoUserSession | any) {
      const idToken = result.idToken;
      const payload = idToken.payload;
      setTimestamp(+payload['custom:timestamp'] || 0);
      setAuthStatus({
        userId: payload.sub,
        idToken: idToken.jwtToken,
        timestamp: +payload['custom:timestamp'] || 0,
        authenticated: true
      });
      setUserData({
        username: payload['cognito:username'],
        email: payload.email
      });
    },
    onFailure(err) {
      setError(err.message);
    }
  });
  return;
};

/** Return user auth object from local storage value */
export const getStoredUserAuth = (global: any): CustomTypes.UserAuth => {
  const auth = global.sessionStorage.getItem(USER_AUTH_KEY);
  if (auth) {
    return JSON.parse(auth);
  }
  return DEFAULT_USER_AUTH;
};

export const cleanSession = (global: any) => {
  global.sessionStorage.removeItem(USER_AUTH_KEY);
  global.sessionStorage.removeItem(USER_DATA_KEY);
};
