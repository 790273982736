import { useState, useContext } from 'react';
import GlobalContext from '../components/contexts/GlobalContext';

export const getStoredData = (global: any, key: string, defaulfValue: any): any => {
  const data = global.sessionStorage.getItem(key);
  if (data) {
    return JSON.parse(data);
  }

  return defaulfValue;
};

/**
 * React hook for reading and writing value to session storage
 *
 * @param key
 * @param defaulfValue
 * @return array of data, setData, resetData
 */
const useSessionStorage = (key: string, defaulfValue: any) => {
  const global: any = useContext(GlobalContext);
  const [state, setState] = useState(getStoredData(global, key, defaulfValue));

  const setData = (data: any) => {
    global.sessionStorage.setItem(key, JSON.stringify(data));
    setState(data);
  };

  const resetData = () => {
    global.sessionStorage.removeItem(key);
    setState(defaulfValue);
  };

  return [state, setData, resetData];
};

export default useSessionStorage;
