import React, { useState } from 'react';
import styled from 'styled-components';
import { useLazyQuery } from '@apollo/react-hooks';
import { GET_NODE_MASTER } from '../../graphql/queries';
import {
  WaitIcon,
  StatusActiveIcon,
  StatusInactiveIcon,
  RedoIcon,
  IniFileIcon,
  DownloadFilesIcon
} from '../Icons/Icons';
import { Menu, MenuItem } from '@material-ui/core';
import TimeAgo from 'react-timeago';
import Tooltip from '@material-ui/core/Tooltip';
import { getDislayStatusFor } from '../../helpers/nodeMaster';
import GlobalContext from '../contexts/GlobalContext';

export interface NodeMasterStatus {
  connected: boolean;
  softwareVersion: string;
  timezone: string;
}

export interface NodeMasterFile {
  key: string;
  lastModified: string;
  url: string;
}

export interface NodeMasterDisplayStatus {
  title: string;
  description: string;
}

export interface NodeMasterInterface {
  uuid: string;
  nodeMasterId: string;
  name: string;
  organisationName: string;
  url: string;
  lastContactTime: string;
  children?: string;
  latitude: number;
  longitude: number;
  status?: NodeMasterStatus;
  files?: Array<NodeMasterFile>;
}

export const StyledNodeMasterListItem = styled.li`
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  position: relative;
  overflow: hidden;

  .top {
    display: flex;
    flex-direction: row;
    align-items: center;

    .icon {
      margin-right: 1rem;
      color: #8cbf30;

      &--inactive {
        color: red;
      }

      &--loading {
        color: #666;
        animation: spin 3s infinite;
      }
    }
  }

  .title {
    font-size: 1rem;
    font-weight: 400;
  }

  .subtitle {
    display: block;
    font-size: 0.8rem;
    color: #7b879e;
  }

  .details {
    color: #7b879e;
    font-size: 0.7rem;
    padding-bottom: 3rem;

    .details-item {
      margin: 0.1rem 0;
    }
  }

  .bottom {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #f5f6fa;
    text-align: center;
    padding: 0.5rem 0;

    a {
      font-size: 0.8rem;
      color: #8cbf30;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    button {
      font-size: 0.8rem;
      background: none;
      margin-left: 1rem;
      border: 0;
    }
  }

  .details {
    dt,
    dd {
      display: inline-block;
    }

    dd {
      margin-left: 0.3rem;
    }
  }
`;

const useDownloadMenu = (files?: Array<NodeMasterFile>): any => {
  const global = React.useContext(GlobalContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const renderMenuItem = (file: NodeMasterFile) => {
    const title = file.key.split('/').pop();

    return (
      <MenuItem
        key={file.key}
        onClick={() => {
          global.open(file.url, '_blank');
          setAnchorEl(null);
        }}
      >
        <IniFileIcon /> {title}
      </MenuItem>
    );
  };

  if (!files) {
    return [null, () => {}];
  }

  const menu = (
    <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} keepMounted onClose={() => setAnchorEl(null)}>
      {files.map(renderMenuItem)}
    </Menu>
  );

  return [
    menu,
    (el: any) => {
      setAnchorEl(el);
    }
  ];
};

const NodeMasterListItem = (props: NodeMasterInterface) => {
  const [uuid] = useState(props.uuid);
  const [name, setName] = useState(props.name);
  const [organisationName, setOrganisationName] = useState(props.organisationName);
  const [url, setUrl] = useState(props.url);
  const [lastContactTime, setLastContactTime] = useState(props.lastContactTime);
  const [status, setStatus] = useState(props.status);
  const [files] = useState(props.files);
  const [toReload, setToReload] = useState(false);
  const isConnected = status && status.connected === true;
  const [getNodeMaster, { loading, data }] = useLazyQuery(GET_NODE_MASTER, {
    variables: {
      uuid
    },
    fetchPolicy: 'cache-and-network'
  });
  const [downloadMenu, handleOpen] = useDownloadMenu(files);

  if (toReload && data && data.nodemasterByUUID) {
    const updated: NodeMasterInterface = data.nodemasterByUUID;
    setName(updated.name);
    setOrganisationName(updated.organisationName);
    setStatus(updated.status);
    setLastContactTime(updated.lastContactTime);
    setUrl(updated.url);
    setToReload(false);
  }

  const handleReloadClick = () => {
    setToReload(true);
    getNodeMaster();
  };

  const { description } = getDislayStatusFor({
    ...props,
    lastContactTime,
    status
  });

  return (
    <StyledNodeMasterListItem
      data-test-id={`nodemaster-listitem-${uuid}`}
      className="card nodemaster-listitem"
      data-uuid={uuid}
    >
      <div className="top">
        {loading ? (
          <WaitIcon className="icon icon--loading" />
        ) : isConnected ? (
          <Tooltip title={description}>
            <span>
              <StatusActiveIcon className="icon icon--active" />
            </span>
          </Tooltip>
        ) : (
          <Tooltip title={description}>
            <span>
              <StatusInactiveIcon className="icon icon--inactive" />
            </span>
          </Tooltip>
        )}
        <h2 className="title">
          {organisationName}
          <small className="subtitle">{name}</small>
        </h2>
      </div>

      <dl className="details">
        <div className="details-item">
          <dt>Last notification was</dt>
          <dd>
            <TimeAgo date={lastContactTime} />
          </dd>
        </div>

        <div className="details-item">
          <dt>Version</dt>
          <dd>{status && status.softwareVersion ? status.softwareVersion : 'Unknown'}</dd>
        </div>

        <div className="details-item">
          <dt>Mongo ID</dt>
          <dd>{uuid}</dd>
        </div>
      </dl>

      <div className="bottom">
        {files && files.length > 0 ? (
          <>
            <button className="btn-download" onClick={e => handleOpen(e.currentTarget)}>
              <DownloadFilesIcon />
            </button>
            {downloadMenu}
          </>
        ) : null}
        <a className="link-open" href={url} target="_blank" rel="noopener noreferrer">
          Open
        </a>
        <button
          className="btn-reload"
          onClick={() => {
            handleReloadClick();
          }}
          disabled={loading}
        >
          <RedoIcon />
        </button>
      </div>
    </StyledNodeMasterListItem>
  );
};

export default NodeMasterListItem;
