import jwtDecode from 'jwt-decode';
import * as CustomTypes from '../../../types/AuthTypes';
import { DEFAULT_USER_AUTH } from './constants';
import { USER_AUTH_KEY } from './local-storage';

/**
 * Check if a token has expired
 * @param userAuth - user auth object
 */
export const checkIfTokenIsValid = (
  global: any,
  userAuth: CustomTypes.UserAuth,
  setUnauthStatus: () => void
): boolean => {
  if (!userAuth || !userAuth.idToken) {
    global.sessionStorage.setItem(USER_AUTH_KEY, JSON.stringify(DEFAULT_USER_AUTH));
    setUnauthStatus();
    return false;
  }
  try {
    const decodedJwt: any = jwtDecode(userAuth.idToken);
    if (decodedJwt.exp >= Date.now() / 1000) {
      return true;
    } else {
      global.sessionStorage.setItem(USER_AUTH_KEY, JSON.stringify(DEFAULT_USER_AUTH));
      setUnauthStatus();
      return false;
    }
  } catch (e) {
    return false;
  }
};
