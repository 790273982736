import React from 'react';
import styled from 'styled-components';
import { IconLookup, library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { breakpoints } from '@parkingsense/ui';

library.add(fas);

const StyledNodeMastersOverview = styled.section`
  border-radius: 3px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.34);
  background-color: #fff;

  font-size: 1rem;
  padding: 1rem 2rem;

  @media ${breakpoints.md} {
    width: 200px;
  }

  .overview__title {
    font-size: 1rem;
    color: #7b879e;
    font-weight: 400px;
    margin-bottom: 0.5rem;
  }

  .overview__filter-state,
  .overview__total {
    margin: 0.2rem 0;

    em {
      font-style: normal;
    }
  }

  .status-list {
    list-style: none;
    padding: 0;
  }

  .status-list__item {
    font-weight: 600;
    font-size: 0.8rem;
    margin: 0.3rem 0;

    em {
      font-style: normal;
    }
  }

  .status-list__item--active {
    color: #84c446;
  }

  .status-list__item--inactive {
    color: #eb202b;
  }
`;

export interface NodeMastersOverviewProps {
  isFiltered: boolean;
  numTotal: number;
  numActive: number;
  numInactive: number;
}

const NodeMastersOverview: React.FC<NodeMastersOverviewProps> = (props: NodeMastersOverviewProps) => {
  let header = null;
  if (props.isFiltered) {
    header = <p className="overview__filter-state">Node masters</p>;
  } else {
    header = (
      <React.Fragment>
        <h2 className="overview__title">All Organizations</h2>
        <p className="overview__filter-state">All node masters</p>
      </React.Fragment>
    );
  }

  const activeIcon: IconLookup = { prefix: 'fas', iconName: 'arrow-circle-up' };
  const inactiveIcon: IconLookup = { prefix: 'fas', iconName: 'arrow-circle-down' };

  return (
    <StyledNodeMastersOverview>
      <div className="nodemasters-overview">
        {header}
        <p className="overview__total">
          Total <em>{props.numTotal}</em>
        </p>
        <ul className="status-list">
          <li className="status-list__item status-list__item--active">
            <FontAwesomeIcon icon={activeIcon} size="1x" className="icon" /> <em>{props.numActive}</em> active
          </li>
          <li className="status-list__item status-list__item--inactive">
            <FontAwesomeIcon icon={inactiveIcon} size="1x" className="icon" /> <em>{props.numInactive}</em> inactive
          </li>
        </ul>
      </div>
    </StyledNodeMastersOverview>
  );
};

export default NodeMastersOverview;
