import React, { FormEvent } from 'react';
import { confirmPassword as resetPassword } from './resetPasswordUtils';
import { Link } from 'react-router-dom';
import { TextField, FormTitle, FormDescription, Button, FormActionsWrapper } from '@parkingsense/ui';
import { useHistory } from 'react-router-dom';
import { constants } from '@parkingsense/ui';
import { ResetPasswordSubFormProps } from './ResetPasswordForm';
import { FormValidationResult, FieldError } from '@parkingsense/ui';

export const validateChangePasswordForm = (data: any): FormValidationResult => {
  const errors: FieldError = {};

  if (!Boolean(data.username)) {
    errors['username'] = 'Please enter a valid username';
  }
  if (!Boolean(data.code)) {
    errors['code'] = 'Please enter a valid verification code';
  }
  if (!Boolean(data.password)) {
    errors['password'] = 'Please enter a valid password';
  } else if (!constants.PASSWORD_PATTERN.test(data.password)) {
    errors['password'] = 'Requires 8+ length, uppercase, lowercase, numbers and special characters.';
  }
  if (!Boolean(data.confirmPassword)) {
    errors['confirmPassword'] = 'Please enter password again';
  } else if (data.confirmPassword !== data.password) {
    errors['confirmPassword'] = 'Password confirmation does not match';
  }

  return Object.keys(errors).length === 0 ? {} : { errors };
};

const ChangePasswordForm = (props: ResetPasswordSubFormProps) => {
  const history = useHistory();
  const [username, setUsername] = React.useState(props.formData.username || '');
  const [code, setCode] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [errors, setErrors] = React.useState<FieldError>({});
  const handleFormSubmission = (e: FormEvent) => {
    e.preventDefault();
    const validateResult = validateChangePasswordForm({
      username,
      code,
      password,
      confirmPassword
    });

    if (validateResult.errors) {
      setErrors(validateResult.errors);
      return;
    }

    resetPassword(username, code, password, {
      onSuccess: () => {
        props.onMessage({
          text: 'Password change complete. You can now login with your new password.',
          type: 'success'
        });
        history.push(constants.URLS.SIGN_IN);
      },
      onFailure: (err: any) => {
        props.onMessage({ text: err.message, type: 'warning' });
      }
    });
  };

  return (
    <form onSubmit={handleFormSubmission} data-test-id="changePasswordForm">
      <FormTitle>Change Password</FormTitle>
      <FormDescription>Please enter your new password.</FormDescription>

      <div>
        <TextField
          label="Username *"
          name="username"
          autoComplete="off"
          value={username}
          onChange={(e: any) => setUsername(e.target.value)}
          error={Boolean(errors['username'])}
          helperText={errors['username']}
        />
      </div>
      <div>
        <TextField
          label="Verification Code *"
          name="verification-code"
          autoComplete="off"
          value={code}
          onChange={(e: any) => setCode(e.target.value)}
          error={Boolean(errors['code'])}
          helperText={errors['code']}
        />
      </div>
      <div>
        <TextField
          type="password"
          label="Password *"
          name="password"
          autoComplete="off"
          value={password}
          onChange={(e: any) => setPassword(e.target.value)}
          error={Boolean(errors['password'])}
          helperText={errors['password']}
        />
      </div>
      <div>
        <TextField
          type="password"
          label="Confirm Password *"
          name="confirm-password"
          autoComplete="off"
          value={confirmPassword}
          onChange={(e: any) => setConfirmPassword(e.target.value)}
          error={Boolean(errors['confirmPassword'])}
          helperText={errors['confirmPassword']}
        />
      </div>
      <FormActionsWrapper>
        <Link data-test-id="goBackToLoginLink" to={constants.URLS.SIGN_IN}>
          Go back to login
        </Link>
        <br />
        <Button data-test-id="resetPasswordButton" type="submit">
          Reset password
        </Button>
        <br />
        <Button data-test-id="getNewVerificationCodeButton" type="button" onClick={() => props.onSetState('get-code')}>
          Get a new code
        </Button>
        <br />
      </FormActionsWrapper>
    </form>
  );
};

export default ChangePasswordForm;
