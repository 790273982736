import * as React from 'react';
import { TitleBar } from './components/TitleBar/TitleBar';
import { Footer } from './components/Footer/Footer';
import { MemoryRouter } from 'react-router';
import { Route } from 'react-router-dom';
import { History } from 'history';
import { ApolloProvider } from '@apollo/react-common';

import './App.css';
import './helpers/NavBar.css';
import { NodeMasterDashboard } from './components/NodeMasterDashboard/NodeMasterDashboard';
import { MapDashboard } from './components/MapDashboard/MapDashboard';
import { cleanSession } from './components/views/AuthUtils/auth';
import { ClickAwayListener } from '@material-ui/core';
import SideNav, { NavIcon, NavItem, NavText } from './helpers/NavBarStyled';
import { DashboardIcon, MapMarkerIcon, LogoutIcon } from './components/Icons/Icons';
import { createPrivateGraphQlClient } from './helpers/apiClients';
import GlobalContext from './components/contexts/GlobalContext';

export interface AppProps {
  expanded: boolean;
  selected: string;
  selectedOrg?: string;
  history: History;
}

const AppNavigation: React.FC<AppProps> = (props: AppProps) => {
  const global = React.useContext(GlobalContext);
  const [selected] = React.useState(props.selected);
  const [expanded, setExpanded] = React.useState(false);

  const onToggle = (): void => {
    setExpanded(!expanded);
  };

  const handleLogout = () => {
    cleanSession(global);
    props.history.push('/');
  };

  return (
    <MemoryRouter>
      <ApolloProvider client={createPrivateGraphQlClient(global)}>
        <Route
          render={({ location, history }) => (
            <>
              <ClickAwayListener onClickAway={() => setExpanded(false)}>
                <SideNav
                  id="sidenav"
                  data-test-id="sidenav"
                  expanded={expanded}
                  onToggle={onToggle}
                  onSelect={(selected: string) => {
                    const to = '/' + selected;
                    if (location.pathname !== to) {
                      history.push(to);
                    }
                  }}
                >
                  <SideNav.Toggle data-test-id="sidenav__toggle" />
                  <SideNav.Nav selected={selected} defaultSelected="dashboard">
                    <NavItem data-test-id="sidenav__item--node-masters" eventKey="nodemasters">
                      <NavIcon>
                        <DashboardIcon />
                      </NavIcon>
                      <NavText style={{ paddingRight: 32 }} title="Dashboard">
                        Node Masters
                      </NavText>
                    </NavItem>
                    <NavItem data-test-id="sidenavItemMap" eventKey="map">
                      <NavIcon>
                        <MapMarkerIcon />
                      </NavIcon>
                      <NavText style={{ paddingRight: 32 }} title="Map">
                        Map
                      </NavText>
                    </NavItem>
                    <NavItem data-test-id="sidenavItemLogout" eventKey="logout" onClick={() => handleLogout()}>
                      <NavIcon>
                        <LogoutIcon />
                      </NavIcon>
                      <NavText style={{ paddingRight: 32 }} title="Logout">
                        Logout
                      </NavText>
                    </NavItem>
                  </SideNav.Nav>
                </SideNav>
              </ClickAwayListener>
              <div className={`${expanded ? 'expanded-menu' : 'closed-menu'} main`} data-test-id="main">
                <TitleBar onLogoutClick={handleLogout} />
                <Route path="/" exact component={() => <NodeMasterDashboard />} />
                <Route path="/nodemasters" component={() => <NodeMasterDashboard />} />
                <Route path="/map" component={() => <MapDashboard />} />
                <Footer />
              </div>
            </>
          )}
        />
      </ApolloProvider>
    </MemoryRouter>
  );
};

export default AppNavigation;
