import * as React from 'react';
import styled from 'styled-components';
import { breakpoints } from '@parkingsense/ui';
import './TitleBar.css';
import { authContext } from '../contexts/AuthContext';
import { Menu, MenuItem } from '@material-ui/core';
import { UserIcon, ChevDownIcon } from '../Icons/Icons';

export const StyledUserName = styled.span`
  max-width: 10rem;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 0.5rem;
  margin: 0;
  font-size: 13px;
  font-weight: regular;
  letter-spacing: 0;
  color: #4d4f5c;
  text-align: right;

  @media ${breakpoints.md} {
    max-width: 20rem;
  }
`;

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;

  .header__user-menu-toggle {
    border: 0;
    background: none;
    margin-right: 1rem;
  }

  .header__icon {
    color: #333;
    font-size: 1rem;
  }

  .header__icon--user {
    font-size: 1.5rem;
  }
`;

export type TitleBarProps = {
  onLogoutClick: () => void;
};

export const TitleBar = (props: TitleBarProps) => {
  const { userData } = React.useContext(authContext);
  const initialAchor: any = null;
  const [userMenuAnchor, setUserMenuAnchor] = React.useState(initialAchor);

  return (
    <header className="header" data-test-id="appHeader">
      <img width={150} height={22} src={require('../../images/logo.svg')} alt="StatusNet2.0" />
      <StyledWrapper>
        <StyledUserName>{userData.email}</StyledUserName>
        <button className="header__user-menu-toggle" onClick={e => setUserMenuAnchor(e.currentTarget)}>
          <ChevDownIcon className="header__icon" />
        </button>
        <UserIcon className="header__icon header__icon--user" />
      </StyledWrapper>
      <Menu
        keepMounted
        open={Boolean(userMenuAnchor)}
        anchorEl={userMenuAnchor}
        onClose={() => setUserMenuAnchor(null)}
      >
        <MenuItem onClick={props.onLogoutClick}>Logout</MenuItem>
      </Menu>
    </header>
  );
};
