import * as React from 'react';
import { FunctionComponent } from 'react';
import { ReactComponent as Logo } from '../../images/logo-ps.svg';

import './Footer.css';

export const Footer: FunctionComponent = () => {
  return (
    <footer className="footer">
      <Logo width={150} />
    </footer>
  );
};
