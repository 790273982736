import * as React from 'react';
import { authContext } from '../../../contexts/AuthContext';
import * as auth from '../auth';
import { ReactComponent as Logo } from '../../../../images/logo-ps.svg';
import { Link } from 'react-router-dom';
import { constants } from '@parkingsense/ui';
import { LoginForm, UnauthenticatedView } from '@parkingsense/ui';
import GlobalContext from '../../../contexts/GlobalContext';

/** Login UI */
const SignInFormView: React.FC<{}> = () => {
  const { setTimestamp, setAuthStatus, setUserData } = React.useContext(authContext);
  const global = React.useContext(GlobalContext);
  const logo = <Logo className="login-page__logo" />;
  const forgotPasswordLink = (
    <Link to={constants.URLS.RESET_PASSWORD} data-test-id="passwordPasswordLink">
      Forgot password?
    </Link>
  );
  return (
    <UnauthenticatedView data-test-id="loginPage" logo={logo} image={require('../../../../images/hero-img.jpg')}>
      <LoginForm
        forgotPasswordLink={forgotPasswordLink}
        onLoginAttempt={(username, password) => {
          auth.signIn(
            username,
            password,
            'dashboard',
            err => {
              global.setAppMessage({
                text: err,
                type: 'warning'
              });
            },
            setTimestamp,
            setAuthStatus,
            setUserData
          );
        }}
      />
    </UnauthenticatedView>
  );
};
export default SignInFormView;
