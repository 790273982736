import * as React from 'react';
import { Redirect } from 'react-router';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { checkIfTokenIsValid } from './components/views/AuthUtils/helpers';
import { USER_AUTH_KEY } from './components/views/AuthUtils/local-storage';
import Routes from './MainAuthRoutes';
/** Contexts */
import AuthContextProvider, { authContext } from './components/contexts/AuthContext';

import './App.css';
import './helpers/NavBar.css';
import './styles/material-ui-overrides.css';
import GlobalContext from './components/contexts/GlobalContext';
import AppMessage from './components/AppMessage/AppMessage';
import MessageType from './types/MessageType';

const AuthRoute = (props: any) => {
  const { setUnauthStatus } = React.useContext(authContext);
  const Comp = props.component;
  return props.private &&
    !checkIfTokenIsValid(
      window,
      JSON.parse(window.sessionStorage.getItem(USER_AUTH_KEY) || JSON.stringify('{}')),
      setUnauthStatus
    ) ? (
    <Redirect to="/sign-in" />
  ) : props.private &&
    checkIfTokenIsValid(
      window,
      JSON.parse(window.sessionStorage.getItem(USER_AUTH_KEY) || JSON.stringify('{}')),
      setUnauthStatus
    ) ? (
    <Route render={data => <Comp {...data} {...props} />} />
  ) : (
    <Route render={data => <Comp {...data} {...props} />} />
  );
};

function App() {
  const defaultAppMessage: MessageType = { text: '', type: 'info' };
  const [appMessage, setAppMessage] = React.useState(defaultAppMessage);

  return (
    <GlobalContext.Provider
      value={{
        sessionStorage: window.sessionStorage,
        open: window.open.bind(window),
        setTimeout: window.setTimeout.bind(window),
        appMessage,
        setAppMessage
      }}
    >
      <AuthContextProvider>
        <div className="fill-window">
          <Router>
            <React.Fragment>
              <Switch>
                {Routes.map((route, i) => {
                  return <AuthRoute key={i} {...route} />;
                })}
              </Switch>
            </React.Fragment>
          </Router>
        </div>
      </AuthContextProvider>

      <AppMessage message={appMessage} />
    </GlobalContext.Provider>
  );
}

export default App;
