import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import SideNav, { Toggle, Nav, NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import styled from 'styled-components';

// Styled SideNav
const StyledSideNav = styled(SideNav)`
  background-color: #43425D !important;
  color: white;
  opacity: 1;

    &&:not([class*="sidenav---expanded"] {
        color: white;
        > [class*="sidenav---sidenav-navitem--"]:hover {
            // Hovered style
        }
    }

`;
StyledSideNav.defaultProps = SideNav.defaultProps;

// Styled Nav
const StyledNav = styled(Nav)`
  background-color: #43425d;
  color: white;
  opacity: 1;
`;
StyledNav.defaultProps = Nav.defaultProps;

// Styled NavItem
const StyledNavItem = styled(NavItem)`
  background-color: #43425d !important;
  color: white !important;
  opacity: 1;
`;
StyledNavItem.defaultProps = NavItem.defaultProps;

// Styled NavIcon
const StyledNavIcon = styled(NavIcon)`
  color: white !important;
  opacity: 1;
`;
StyledNavIcon.defaultProps = NavIcon.defaultProps;

export {
  Toggle,
  StyledNav as Nav, // Styled Nav
  StyledNavItem as NavItem,
  StyledNavIcon as NavIcon,
  NavText
};
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
export default StyledSideNav as SideNav; // Styled SideNav
