import React from 'react';
import styled from 'styled-components';
import NodeMasterList from '../NodeMasterList/NodeMasterList';
import { NodeMasterInterface } from '../NodeMasterListItem/NodeMasterListItem';
import NodeMastersOverview, { NodeMastersOverviewProps } from '../NodeMastersOverview/NodeMastersOverview';
import { GET_NODE_MASTERS } from '../../graphql/queries';
import { useLazyQuery } from '@apollo/react-hooks';
import NodeMastersFilter from '../NodeMastersFilter/NodeMastersFilter';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import ErrorPanel from '../ErrorPanel/ErrorPanel';
import { RedoIcon } from '../Icons/Icons';
import { areKeywordsMatched } from '../../helpers/nodeMaster';

export const StyledNodeMasterDashboard = styled.section`
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  flex-shrink: 10;
  padding: 1rem;
  border: 1 solid;
  font-size: 1rem;
  font-weight: regular;
  text-align: left;

  .filter-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .btn-reload {
    height: 2rem;
    width: 2rem;
    margin-left: 1rem;
    border: 0;
    background: none;

    svg {
      font-size: 1.2rem;
    }
  }
`;

export const NodeMasterDashboard: React.FC = () => {
  const [fetchNodeMasters, { loading, error, data }] = useLazyQuery(GET_NODE_MASTERS, {
    fetchPolicy: 'cache-and-network'
  });
  const [filterKeywords, setFilterKeywords] = React.useState('');
  // Set to `true` for initial fetch
  const [toFetch, setToFetch] = React.useState(true);

  if (loading) return <LoadingIndicator />;
  if (error) {
    return <ErrorPanel message="There was an error fetching data. Please reload the page." />;
  }

  // For initial load
  if (toFetch) {
    setFilterKeywords('');
    setToFetch(false);
    fetchNodeMasters();
  }

  let nodeMasterList = [];
  if (data && data.nodemaster && data.nodemaster.results) {
    nodeMasterList = data.nodemaster.results.map(
      (node: any): NodeMasterInterface => {
        const {
          uuid,
          nodeMasterId,
          name,
          organisationName,
          url,
          lastContactTime,
          latitude,
          longitude,
          status,
          files
        } = node;
        const mapped: NodeMasterInterface = {
          uuid,
          nodeMasterId,
          name,
          organisationName,
          url,
          lastContactTime,
          latitude,
          longitude,
          status,
          files
        };

        return mapped;
      }
    );
  }

  const filteredNodeMasters = nodeMasterList.filter((node: NodeMasterInterface) =>
    areKeywordsMatched(filterKeywords, node)
  );

  const overviewData: NodeMastersOverviewProps = {
    isFiltered: filterKeywords !== '',
    numTotal: filteredNodeMasters.length,
    numActive: filteredNodeMasters.filter((node: NodeMasterInterface) => node.status && node.status.connected === true)
      .length,
    numInactive: filteredNodeMasters.filter(
      (node: NodeMasterInterface) => !node.status || node.status.connected !== true
    ).length
  };

  return (
    <StyledNodeMasterDashboard data-test-id="nodemastersDashboard">
      <NodeMastersOverview {...overviewData}></NodeMastersOverview>
      <div className="filter-wrapper" data-test-id="filterWrapper">
        <NodeMastersFilter
          onKeywordsChange={keywords => {
            setFilterKeywords(keywords);
          }}
          onKeywordsReset={() => setFilterKeywords('')}
        />
        <button
          className="btn-reload"
          data-test-id="nodemastersDashboardReload"
          onClick={() => {
            setToFetch(true);
          }}
        >
          <RedoIcon />
          <span className="sr-only">Reload node master list</span>
        </button>
      </div>
      <NodeMasterList items={filteredNodeMasters} />
    </StyledNodeMasterDashboard>
  );
};
