import React from 'react';
import { UnauthenticatedView } from '@parkingsense/ui';
import { ReactComponent as Logo } from '../../../images/logo-ps.svg';
import GetCodeForm from './GetCodeForm';
import ChangePasswordForm from './ChangePasswordForm';
import MessageType from '../../../types/MessageType';
import GlobalContext from '../../contexts/GlobalContext';

export type ResetPasswordState = 'get-code' | 'reset-password';

export interface ResetPasswordSubFormProps {
  formData: any;
  onSetState: (state: ResetPasswordState, data?: any) => void;
  onMessage: (message: MessageType) => void;
}

const renderCurrentState = (
  state: string,
  formData: any,
  handleSetState: (state: ResetPasswordState, data?: any) => void,
  handleMessage: (message: MessageType) => void
) => {
  switch (state) {
    case 'reset-password':
      return <ChangePasswordForm formData={formData} onSetState={handleSetState} onMessage={handleMessage} />;
    case 'get-code':
    default:
      return <GetCodeForm formData={formData} onSetState={handleSetState} onMessage={handleMessage} />;
  }
};

const ResetPasswordForm = () => {
  const [state, setState] = React.useState('');
  const [formData, setFormData] = React.useState({});
  const logo = <Logo className="login-page__logo" />;
  const global = React.useContext(GlobalContext);

  const currentState = renderCurrentState(
    state,
    formData,
    (newState, data: any) => {
      setState(newState);
      setFormData({ formData, ...data });
    },
    msg => global.setAppMessage(msg)
  );

  return (
    <UnauthenticatedView data-test-id="loginPage" logo={logo} image={require('../../../images/hero-img.jpg')}>
      {currentState}
    </UnauthenticatedView>
  );
};

export default ResetPasswordForm;
