import * as React from 'react';
/** Custom types */
import { UserAuth, UserData } from '../../types/AuthTypes';
/** Custom Hooks */
/** Utils */
import { DEFAULT_USER_AUTH, DEFAULT_USER_DATA } from '../views/AuthUtils/constants';
import useSessionStorage from '../../hooks/useSessionStorage';
import { USER_DATA_KEY, USER_AUTH_KEY } from '../views/AuthUtils/local-storage';

interface AuthContextInterface {
  confirmationCodeModal: boolean;
  openConfirmationCodeModal: React.Dispatch<React.SetStateAction<boolean>>;
  auth: UserAuth;
  userData: UserData;
  setUserData: (userData: UserData) => void;
  clearUserData: () => void;
  userId: string;
  userTimestamp: number;
  userAccountVerified: boolean;
  setTimestamp: React.Dispatch<React.SetStateAction<number>>;
  setUserAccountVerified: React.Dispatch<React.SetStateAction<boolean>>;
  setUserId: React.Dispatch<React.SetStateAction<string>>;
  setAuthStatus: (userAuth: UserAuth) => void;
  setUnauthStatus: () => void;
}

export const authContext = React.createContext<AuthContextInterface>({
  confirmationCodeModal: false,
  auth: DEFAULT_USER_AUTH,
  userTimestamp: 0,
  userId: '',
  userData: DEFAULT_USER_DATA,
  setUserData: () => {},
  clearUserData: () => {},
  userAccountVerified: false,
  setTimestamp: () => {},
  setUserAccountVerified: () => {},
  setUserId: () => {},
  setAuthStatus: () => {},
  setUnauthStatus: () => {},
  openConfirmationCodeModal: () => {}
});
const { Provider } = authContext;
const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [userTimestamp, setTimestamp] = React.useState(0);
  const [userId, setUserId] = React.useState('');
  const [confirmationCodeModal, openConfirmationCodeModal] = React.useState(false);
  const [userAccountVerified, setUserAccountVerified] = React.useState(false);
  const [auth, setAuthStatus, setUnauthStatus] = useSessionStorage(USER_AUTH_KEY, DEFAULT_USER_AUTH);
  const [userData, setUserData, clearUserData] = useSessionStorage(USER_DATA_KEY, DEFAULT_USER_DATA);

  return (
    <Provider
      value={{
        confirmationCodeModal,
        openConfirmationCodeModal,
        auth,
        setAuthStatus,
        setUnauthStatus,
        userData,
        setUserData,
        clearUserData,
        userAccountVerified,
        setUserAccountVerified,
        userId,
        setUserId,
        userTimestamp,
        setTimestamp
      }}
    >
      {children}
    </Provider>
  );
};
export default AuthProvider;
