import SignIn from './components/views/AuthUtils/SignIn';
import ResetPassword from './components/views/ResetPassword';
import AppNavigation from './AppNavigation';

const mainAuthRoutes = [
  {
    title: 'AppNavigation',
    path: '/',
    component: AppNavigation,
    exact: true,
    private: true
  },
  {
    title: 'Sign In',
    path: '/sign-in',
    component: SignIn,
    exact: true,
    private: false
  },
  {
    title: 'Reset Password',
    path: '/reset-password',
    component: ResetPassword,
    exact: true,
    private: false
  }
];
export default mainAuthRoutes;
