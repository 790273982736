import * as React from 'react';
import { Redirect } from 'react-router';
import { authContext } from '../../contexts/AuthContext';
import ResetPasswordForm from './ResetPasswordForm';

const ResetPassword = () => {
  const { auth } = React.useContext(authContext);

  if (auth.authenticated) {
    return <Redirect to="/" />;
  }

  return <ResetPasswordForm />;
};

export default ResetPassword;
