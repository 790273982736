import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { breakpoints } from '@parkingsense/ui';
import { IconLookup, library } from '@fortawesome/fontawesome-svg-core';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { TextField } from '@material-ui/core';

library.add(fal);

export const StyledNodeMasterFilter = styled.section`
  width: 100%;
  margin: 2rem 0;

  @media ${breakpoints.md} {
    width: 500px;
  }

  .wrapper {
    display: flex;
    flex-direction: row;
    padding-top: 1rem;
    padding-bottom: 2rem;
    align-items: baseline;
  }

  .keywords-input {
    width: 80%;
    border: 0;
    margin-right: 0.5rem;

    @media ${breakpoints.md} {
      width: calc(100% - 40px);
    }

    label.Mui-focused {
      color: #8cbf30;
    }

    .MuiInput-underline:after {
      border-bottom-color: #8cbf30;
    }

    &:focus {
      outline: none;
      border-color: #8cbf30;
      border-width: 2px;
    }
  }

  button {
    border: 0;
    background: none;
  }

  .title {
    display: block;
    color: #8cbf30;
  }
`;

export interface NodeMasterFilterProps {
  onKeywordsChange: (keywords: string) => void;
  onKeywordsReset: () => void;
}

const NodeMastersFilter: React.FC<NodeMasterFilterProps> = (props: NodeMasterFilterProps) => {
  const resetIcon: IconLookup = { prefix: 'fal', iconName: 'times' };
  const [keywords, setKeywords] = React.useState('');

  return (
    <StyledNodeMasterFilter>
      <div className="card wrapper nodemasters-filter">
        <TextField
          label="Search"
          value={keywords}
          onChange={e => {
            const val = e.target.value;
            setKeywords(val);
            props.onKeywordsChange(val);
          }}
          className="keywords-input"
        />
        {keywords.trim() !== '' ? (
          <button
            data-test-id="nodemastersFilterResetButton"
            onClick={() => {
              setKeywords('');
              props.onKeywordsReset();
            }}
          >
            <FontAwesomeIcon icon={resetIcon} size="1x" className="icon" />
            <span className="sr-only">Reset node master list filter</span>
          </button>
        ) : null}
      </div>
    </StyledNodeMasterFilter>
  );
};

export default NodeMastersFilter;
