import { gql } from 'apollo-boost';

// @todo Implement pagination
export const GET_NODE_MASTERS = gql`
  query getNodeMasters {
    nodemaster(first: 100) {
      results {
        uuid
        name
        url
        nodeMasterId
        lastContactTime
        organisationName
        latitude
        longitude
        status {
          connected
          softwareVersion
          timezone
        }
        files {
          key
          url
          lastModified
        }
      }
      pageInfo {
        nextCursor
      }
    }
  }
`;

export const GET_NODE_MASTER = gql`
  query getNodeMaster($uuid: String) {
    nodemasterByUUID(uuid: $uuid) {
      uuid
      name
      url
      nodeMasterId
      lastContactTime
      organisationName
      latitude
      longitude
      status {
        connected
        softwareVersion
        timezone
      }
      files {
        key
        url
        lastModified
      }
    }
  }
`;

// @todo Implement pagination
export const GET_LOCATIONS = gql`
  query getLocations {
    location(first: 100) {
      results {
        uuid
        name
        streetAddress
        description
        latitude
        longitude
        areas(first: 100) {
          results {
            uuid
            name
            bayTypeSummary {
              bayType
              bayLabel
              total
              free
              occupied
            }
          }
          pageInfo {
            nextCursor
          }
        }
        bayTypeSummary {
          bayType
          bayLabel
          total
          occupied
          free
        }
      }
      pageInfo {
        nextCursor
      }
    }
  }
`;
