import React from 'react';
import { IconLookup, library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';

library.add(fas);
library.add(fal);
library.add(fad);

const BaseIcon = (props: FontAwesomeIconProps) => {
  return <FontAwesomeIcon icon={props.icon} size="1x" className="icon" {...props} />;
};

export const WaitIcon = (props: any) => {
  const icon: IconLookup = { prefix: 'fal', iconName: 'hourglass' };
  return <BaseIcon {...props} icon={icon} />;
};

export const StatusActiveIcon = (props: any) => {
  const icon: IconLookup = { prefix: 'fas', iconName: 'arrow-circle-up' };
  return <BaseIcon {...props} icon={icon} />;
};

export const StatusInactiveIcon = (props: any) => {
  const icon: IconLookup = { prefix: 'fas', iconName: 'arrow-circle-down' };
  return <BaseIcon {...props} icon={icon} />;
};

export const RedoIcon = (props: any) => {
  const icon: IconLookup = { prefix: 'fas', iconName: 'redo-alt' };
  return <BaseIcon {...props} icon={icon} />;
};

export const WarningIcon = (props: any) => {
  const icon: IconLookup = { prefix: 'fad', iconName: 'exclamation-triangle' };
  return <BaseIcon {...props} icon={icon} />;
};

export const LoadingIcon = (props: any) => {
  const icon: IconLookup = { prefix: 'fad', iconName: 'spinner-third' };
  return <BaseIcon {...props} icon={icon} />;
};

export const DashboardIcon = (props: any) => {
  const icon: IconLookup = { prefix: 'fal', iconName: 'drone' };
  return <BaseIcon {...props} icon={icon} />;
};

export const MapMarkerIcon = (props: any) => {
  const icon: IconLookup = { prefix: 'fal', iconName: 'map-marker-alt' };
  return <BaseIcon {...props} icon={icon} />;
};

export const LogoutIcon = (props: any) => {
  const icon: IconLookup = { prefix: 'fal', iconName: 'sign-out-alt' };
  return <BaseIcon {...props} icon={icon} />;
};

export const DownloadFilesIcon = (props: any) => {
  const icon: IconLookup = { prefix: 'fal', iconName: 'cog' };
  return <BaseIcon {...props} icon={icon} />;
};

export const IniFileIcon = (props: any) => {
  const icon: IconLookup = { prefix: 'fal', iconName: 'wrench' };
  return <BaseIcon {...props} icon={icon} />;
};

export const UserIcon = (props: any) => {
  const icon: IconLookup = { prefix: 'fas', iconName: 'user-circle' };
  return <BaseIcon {...props} icon={icon} />;
};

export const ChevDownIcon = (props: any) => {
  const icon: IconLookup = { prefix: 'fas', iconName: 'chevron-down' };
  return <BaseIcon {...props} icon={icon} />;
};
