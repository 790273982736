import React from 'react';
import { Snackbar, SnackbarContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import GlobalContext from '../contexts/GlobalContext';
import MessageType from '../../types/MessageType';
import { constants } from '@parkingsense/ui';

const useSnackbarStyles = makeStyles(() => ({
  success: {
    backgroundColor: constants.COLORS.BRAND_TOYOTA
  },
  error: {
    backgroundColor: constants.COLORS.BRAND_HONDA
  },
  info: {
    backgroundColor: constants.COLORS.BRAND_HYUNDAI
  },
  warning: {
    backgroundColor: constants.COLORS.BRAND_HONDA_40
  }
}));

export type AppMesageProps = {
  message: MessageType;
};

const AppMessage = (props: AppMesageProps) => {
  const classes = useSnackbarStyles();
  const global = React.useContext(GlobalContext);

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      autoHideDuration={3000}
      open={Boolean(props.message.text)}
      onClose={() => {
        global.setAppMessage({ text: '', type: 'info' });
      }}
    >
      <SnackbarContent className={classes[props.message.type]} message={props.message.text} />
    </Snackbar>
  );
};

export default AppMessage;
